import {registerLocaleData} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import localeDECH from '@angular/common/locales/de-CH';
import localeFRCH from '@angular/common/locales/fr-CH';
import localeITCH from '@angular/common/locales/it-CH';
import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {merge, reduceRight} from 'lodash';
import packageInfo from 'package.json';
import {Observable, forkJoin, map} from 'rxjs';

registerLocaleData(localeDECH);
registerLocaleData(localeFRCH);
registerLocaleData(localeITCH);

const translationFolders = [
  '/msa-portal-ui/assets/i18n/',
  '/msa-portal-ui/assets/i18n/landing-page/',
  '/msa-portal-ui/assets/i18n/agreement/',
  '/msa-portal-ui/assets/i18n/dvs/',
  '/msa-portal-ui/assets/i18n/profile_settings/',
  '/msa-portal-ui/assets/i18n/military-career/',
  '/msa-portal-ui/assets/i18n/notifications/',
  '/msa-portal-ui/assets/i18n/leave/'
];

class MultiTranslationLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private listOfTranslations: string[]
  ) {}

  public getTranslation(lang: string): Observable<object> {
    return forkJoin(
      this.listOfTranslations.flatMap(folder => this.http.get(folder + lang + `.json?v=${packageInfo.version}`))
    ).pipe(map(responses => reduceRight(responses, (result: object, obj: object) => merge(result, obj), {})));
  }
}

export function multiTranslationLoaderFactory(http: HttpClient, listOfTranslations: string[]) {
  return new MultiTranslationLoader(http, listOfTranslations);
}

export const TRANSLATION_LIST = new InjectionToken<string[]>('TranslationList');

@NgModule({
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: multiTranslationLoaderFactory,
        deps: [HttpClient, TRANSLATION_LIST]
      }
    }),
    HttpClientModule
  ],
  exports: [TranslateModule]
})
export class MsaTranslateModule {
  constructor(private translate: TranslateService) {
    this.translate.addLangs(['de', 'fr', 'it']);
    this.translate.use('de');
  }

  // only load translation that are used in the core application
  static rootOnly(): ModuleWithProviders<MsaTranslateModule> {
    return {
      ngModule: MsaTranslateModule,
      providers: [{provide: TRANSLATION_LIST, useValue: [translationFolders[0]]}]
    };
  }

  // fetch translations of all submodules and features
  static all(): ModuleWithProviders<MsaTranslateModule> {
    return {
      ngModule: MsaTranslateModule,
      providers: [{provide: TRANSLATION_LIST, useValue: translationFolders}]
    };
  }
}
