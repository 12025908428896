<a class="tw-flex tw-h-[93px] tw-items-center" routerLink="/">
  <mat-icon class="!tw-h-[50px] !tw-w-[225px]" svgIcon="logo:armee" appLanguageIcon />
</a>
<div class="tw-mb-60 tw-mt-30 tw-flex tw-justify-center d:tw-mb-120 d:tw-mt-60">
  <div class="tw-max-w-[890px]" [attr.data-cy]="'userNotLoaded'">
    <h1 class="msa-text-h1 tw-mb-30 d:tw-mb-60">
      {{ "i18n.userNotLoaded.title" | safeTranslate }}
    </h1>
    <div class="msa-text tw-flex tw-flex-col tw-gap-y-9 d:tw-gap-y-18">
      <p>
        {{ "i18n.userNotLoaded.statement" | safeTranslate }}
      </p>
      <!-- eslint-disable-next-line prettier/prettier -->
      <ul class="tw-ms-21 tw-list-square [&_a]:tw-underline">
        <li [innerHTML]="'i18n.userNotLoaded.hotlinePersA' | safeTranslate"></li>
        <li [innerHTML]="'i18n.userNotLoaded.militaryAuthority' | safeTranslate"></li>
      </ul>
      <p>
        {{ "i18n.userNotLoaded.info" | safeTranslate }}
      </p>
    </div>
    <div class="tw-mt-30 d:tw-mt-60">
      <msa-button
        class="d:tw-flex d:tw-justify-center"
        routerLink="/"
        color="primary"
        widthClass="tw-w-full d:tw-w-1/3"
      >
        {{ "i18n.userNotLoaded.buttonText" | safeTranslate }}
      </msa-button>
    </div>
  </div>
</div>
