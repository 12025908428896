@if (bottomBarService.bottomBarData(); as bottomBarData) {
  @if (bottomBarData.show) {
    <div
      class="tw-fixed tw-bottom-0 tw-z-50 tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-12 tw-bg-black tw-py-15 tw-shadow-[0px_-5px_15px_0px_rgba(0,0,0,1)]"
    >
      @if (bottomBarData.icon) {
        <div class="tw-size-21 d:tw-size-24">
          <mat-icon [inline]="true" svgIcon="{{ bottomBarData.icon }}" />
        </div>
      }

      <div class="msa-text-small tw-text-black-light-5">
        {{ bottomBarData.text | translate }}
      </div>
    </div>
  }
}
