import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MsaIconModule} from '@shared/shared-module/msa-icon.module';
import {SafeTranslateModule} from '@shared/shared-module/safe-translate.module';
import {MsaButtonComponent} from '../msa-button/msa-button.component';

@Component({
  selector: 'msa-user-not-loaded',
  templateUrl: './user-not-loaded.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MsaIconModule,
    TranslateModule,
    SafeTranslateModule,
    MsaButtonComponent,
    RouterLink
  ]
})
export class UserNotLoadedComponent {}
