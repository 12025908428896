@if (showVersion()) {
  <div class="msa-text-large tw-mb-18 tw-mt-18 tw-text-center">{{ stageName() | uppercase }}: v{{ version }}</div>
}

<div class="msa-text tw-text- tw-grid tw-grid-cols-2 tw-flex-col tw-gap-18 tw-p-12 tw-text-black d:tw-grid-cols-3">
  @for (flag of featureFlagService.featureFlags(); track flag.name) {
    <div
      class="tw-flex tw-cursor-pointer tw-flex-col tw-rounded tw-p-12"
      [class.tw-bg-blue-light-3]="flag.enabled"
      [class.tw-text-black]="flag.enabled"
      [class.tw-bg-vermillion-light-2]="!flag.enabled"
      [class.tw-text-white]="!flag.enabled"
      (click)="toggleFeature(flag.name!)"
    >
      <div class="msa-text-small tw-flex tw-flex-row tw-items-center tw-justify-between">
        <span class="tw-font-mono">
          {{ flag.name }}
        </span>
        <div class="tw-flex tw-flex-row tw-gap-6">
          <mat-icon>{{ flag.enabled ? "check" : "close" }}</mat-icon>

          @if (flag.diff) {
            <mat-icon class="tw-text-olive-tuscany" matTooltip="Flag is either missing locally or on the remote!"
              >error_outline</mat-icon
            >
          }
        </div>
      </div>

      @if (flag.description && flag.description.length > 0) {
        <span
          class="msa-text-tiny"
          [class.tw-text-black]="flag.enabled"
          [class.tw-text-black-light-6.5]="!flag.enabled"
        >
          {{ flag.description }}
        </span>
      }
    </div>
  }
</div>
