import {OpenIdConfiguration} from 'angular-auth-oidc-client';
import {environment} from 'src/environments/environment';

export const openIdConfiguration: OpenIdConfiguration = {
  authority: environment.oidc.authority,
  redirectUrl: environment.portalUrl + '/oidc-callback',
  postLogoutRedirectUri: environment.portalUrl,
  clientId: environment.oidc.clientId,
  scope: environment.oidc.scope,
  responseType: 'code',
  silentRenew: environment.oidc.silentRenew,
  silentRenewUrl: environment.portalUrl + `/assets/auth/silent-renew.html`,
  renewTimeBeforeTokenExpiresInSeconds: 30,
  useRefreshToken: environment.oidc.useRefreshToken,
  ignoreNonceAfterRefresh: environment.oidc.ignoreNonceAfterRefresh,
  secureRoutes: [...Object.values(environment.api)],
  logLevel: environment.oidc.logLevel,
  unauthorizedRoute: 'unauthorized'
};
