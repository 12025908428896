import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AuthInterceptor, AuthModule} from 'angular-auth-oidc-client';
import {AppRoutingModule} from './app-routing.module';
import {AppStoreModule} from './app-store.module';
import {AppComponent} from './app.component';
import {openIdConfiguration} from './core/constants/oidc-config';

import {BrowserModule} from '@angular/platform-browser';
import {MissingAuthorizationInterceptor} from '@shared/shared-module/interceptors/missing-authorization.interceptor';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {
  msaAdminQueryConfigurationFactory,
  msaDutyServiceConfigurationFactory
} from 'projects/admin-query/src/app/app.module';
import {MsaAdminQueryApiModule} from 'projects/admin-query/src/app/core/api/generated/msa-admin-query/api.module';
import {MsaNotificationApiModule} from 'projects/notifications/src/app/core/api/generated/msa-notification';
import {msaNotificationConfigurationFactory} from 'projects/notifications/src/app/notifications.module';
import {msaPersonDataConfigurationFactory} from 'projects/person-data/src/app/app.module';
import {MsaPersonDataApiModule} from 'projects/person-data/src/app/core/api/generated/msa-person-data';
import {MsaTranslateModule} from './msa-translate.module';
import {MsaLocalizedDatePickerModule} from './shared-module/adapters/msa-localized-date-picker.module';
import {TraceIdHeaderInterceptor} from './shared-module/interceptors/trace-id-interceptor';
import {LoggingService} from './shared-module/services/logging/base-logging.service';
import {ConsoleLoggingService} from './shared-module/services/logging/console-logging.service';
import {MsaDutyServiceApiModule} from '../../projects/admin-query/src/app/core/api/generated/msa-duty-service';
import {
  MsaPortalApiModule,
  MsaPortalConfiguration,
  MsaPortalConfigurationParameters
} from '@shared/core/api/generated/msa-portal';
import {environment} from '../environments/environment';

export function msaPortalConfigurationFactory(): MsaPortalConfiguration {
  const params: MsaPortalConfigurationParameters = {
    basePath: environment.api.portalServiceUrl
  };
  return new MsaPortalConfiguration(params);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppStoreModule,
    HttpClientModule,
    AppComponent,
    MatDialogModule,
    AuthModule.forRoot({config: openIdConfiguration}),
    MatSnackBarModule,
    MsaPersonDataApiModule.forRoot(() => msaPersonDataConfigurationFactory()),
    MsaAdminQueryApiModule.forRoot(() => msaAdminQueryConfigurationFactory()), // This module is added in order to fix the env variables translation
    MsaDutyServiceApiModule.forRoot(() => msaDutyServiceConfigurationFactory()),
    MsaNotificationApiModule.forRoot(() => msaNotificationConfigurationFactory()),
    MsaPortalApiModule.forRoot(() => msaPortalConfigurationFactory()),
    MsaLocalizedDatePickerModule,
    NgxMaterialTimepickerModule,
    MsaTranslateModule.all()
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-CH'},
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {panelClass: 'msa-dialog-panel'} satisfies MatDialogConfig<unknown>
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MissingAuthorizationInterceptor,
      multi: true
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TraceIdHeaderInterceptor,
      multi: true
    },
    {
      provide: LoggingService,
      useClass: ConsoleLoggingService
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [MsaLocalizedDatePickerModule]
})
export class AppModule {}
