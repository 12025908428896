import {NgModule} from '@angular/core';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../environments/environment';
import {AppState} from './shared-module/stores/states/app.state';
import {NotificationsState} from './shared-module/stores/states/notifications.state';
import {PersonDataState} from './shared-module/stores/states/person-data.state';
import {ContactFormGroupState} from '@shared/shared-module/stores/states/contact-form-group.state';
import {LOCAL_STORAGE_ENGINE, NgxsStoragePluginModule} from '@ngxs/storage-plugin';
import {CodeListState} from '@shared/shared-module/stores/states/code-list.state';

@NgModule({
  imports: [
    NgxsModule.forRoot([AppState, CodeListState, PersonDataState, NotificationsState, ContactFormGroupState], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false
      }
    }),
    NgxsStoragePluginModule.forRoot({
      key: [
        {
          key: 'contactForm',
          engine: LOCAL_STORAGE_ENGINE
        }
      ]
    }),
    NgxsReduxDevtoolsPluginModule.forRoot()
  ],
  exports: [NgxsModule]
})
export class AppStoreModule {}
