import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {AppStateSelectors} from '../stores/selectors/app.state.selectors';
import {generateTraceIdHeader} from '../utils/tracking.utils';
import {environment} from '../../../environments/environment';

@Injectable()
export class TraceIdHeaderInterceptor implements HttpInterceptor {
  private store = inject(Store);
  private traceHeaderUrls = Object.values(environment.api);

  intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    if (this.shouldAddTraceHeader(req.url)) {
      const traceId = this.store.selectSnapshot(AppStateSelectors.slices.traceId);
      return next.handle(req.clone({setHeaders: {traceparent: generateTraceIdHeader(traceId)}}));
    }
    return next.handle(req);
  }

  shouldAddTraceHeader(uri: string): boolean {
    return this.traceHeaderUrls.some(api => uri.includes(api));
  }
}
