<div class="tw-mx-auto tw-mb-90 tw-mt-60 tw-max-w-[700px] d:tw-mt-60 d:tw-pb-180">
  <p class="msa-text tw-mb-6 d:tw-mb-12">{{ "i18n.404-page.title" | safeTranslate }}</p>
  <h1 class="msa-text-h1 tw-mb-30 d:tw-mb-60">{{ "i18n.404-page.page-title" | safeTranslate }}</h1>

  <div class="tw-flex tw-flex-col tw-gap-18">
    <div class="tw-flex tw-items-center tw-gap-18">
      <div class="tw-h-60">
        <mat-icon class="!tw-h-full !tw-w-[42px]" svgIcon="commandSupportForces" />
      </div>

      <p class="msa-text">
        {{ "i18n.404-page.first-paragraph" | safeTranslate }}
      </p>
    </div>

    <p class="msa-text tw-col-span-2" [innerHtml]="'i18n.404-page.second-paragraph' | safeTranslate"></p>
  </div>

  <div class="tw-mt-30 d:tw-mt-60"></div>

  <msa-button class="tw-w-fit" data-cy="page-not-found-home-button" color="primary" routerLink="/">
    {{ "i18n.404-page.button" | safeTranslate }}
  </msa-button>
</div>
