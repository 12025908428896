import {CommonModule, UpperCasePipe} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import packageInfo from 'package.json';
import {environment} from 'src/environments/environment';
import {FeatureFlagOverviewComponent} from '../feature-flag-overview/feature-flag-overview.component';

@Component({
  selector: 'msa-stage-indicator',
  templateUrl: './stage-indicator.component.html',
  imports: [CommonModule, UpperCasePipe, MatExpansionModule, FeatureFlagOverviewComponent],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StageIndicatorComponent {
  public featureFlagService = inject(FeatureFlagService);

  stageName = computed(() => environment.TF_ENV.substring(0, environment.TF_ENV.length - 1));

  stageColor = computed(() => {
    switch (this.stageName().toLowerCase()) {
      case 'local':
        return '#7f8c8d';
      case 'dev':
        return '#27ae60';
      case 'ref':
        return '#2980b9';
      case 'abn':
        return '#f39c12';
      default:
        return '#7f8c8d';
    }
  });

  version = packageInfo.version;
}
