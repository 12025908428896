import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FeatureFlagService} from '@shared/shared-module/services/feature-flag/feature-flag.service';
import packageInfo from 'package.json';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'msa-feature-flag-overview',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: 'feature-flag-overview.component.html'
})
export class FeatureFlagOverviewComponent {
  public showVersion = input(true);
  public featureFlagService = inject(FeatureFlagService);

  public stageName = computed(() => environment.TF_ENV.substring(0, environment.TF_ENV.length - 1));
  public version = packageInfo.version;

  public toggleFeature(flagName: string): void {
    this.featureFlagService.toggleFeature(flagName);
  }
}
