import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {DutyContextComponent} from '../../../../../projects/admin-query/src/app/components/duty-context/duty-context.component';
import {BottomBarService} from '../../services/bottom-bar-service/bottom-bar.service';
import {RequiredFieldsNoticeComponent} from '../required-fields-notice/required-fields-notice.component';

@Component({
  selector: 'msa-bottom-bar',
  templateUrl: './msa-bottom-bar.component.html',
  standalone: true,
  imports: [TranslateModule, MatIconModule, CommonModule, RequiredFieldsNoticeComponent, DutyContextComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MsaBottomBarComponent {
  constructor(public bottomBarService: BottomBarService) {}
}
