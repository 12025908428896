<mat-expansion-panel
  class="tw-flex tw-justify-center !tw-rounded-none tw-text-white"
  [style.background-color]="stageColor()"
>
  <mat-expansion-panel-header class="content-wrapper !tw-p-0">
    <mat-panel-title>
      <div class="msa-text tw-text-center tw-text-white">{{ stageName() | uppercase }}: v{{ version }}</div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="content-wrapper">
    <msa-feature-flag-overview [showVersion]="false" />
  </div>
</mat-expansion-panel>
