import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {filter, map, take, tap} from 'rxjs';

import {CdkStepperModule} from '@angular/cdk/stepper';
import {CommonModule, DOCUMENT} from '@angular/common';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {environment} from '../environments/environment';
import {CookieConstants} from './core/constants/cookie.const';
import {LANDING_PAGE_PATH} from './core/constants/url.const';
import {AuthenticationService} from './core/services/authentication.service';
import {MsaBottomBarComponent} from './shared-module/components/msa-bottom-bar/msa-bottom-bar.component';
import {MsaFooterModule} from './shared-module/components/msa-footer/msa-footer.module';
import {MsaHeaderComponent} from './shared-module/components/msa-header/msa-header.component';
import {MsaStepperComponent} from './shared-module/components/msa-stepper/msa-stepper.component';
import {StageIndicatorComponent} from './shared-module/components/stage-indicator/stage-indicator.component';
import {listenOnChildRouteData} from './shared-module/utils/routing.utils';

export const DIM_DEFAULT_LANDING_PAGE = 'admin-query/duties';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    StageIndicatorComponent,
    MsaHeaderComponent,
    MsaFooterModule,
    MsaBottomBarComponent,
    MsaStepperComponent,
    CdkStepperModule
  ]
})
export class AppComponent implements OnInit {
  public translate = inject(TranslateService);
  private document = inject(DOCUMENT);
  private router = inject(Router);
  private authenticationService = inject(AuthenticationService);
  private destroyRef = inject(DestroyRef);

  public fullPageWidth = listenOnChildRouteData(this.router, 'fullPageWidth');

  ngOnInit(): void {
    this.document.documentElement.lang = navigator.language || window.navigator.language;
    this.setupAuthentication();
  }

  setupAuthentication() {
    this.authenticationService
      .checkAuth()
      .pipe(
        take(1),
        filter(Boolean), // filter for authenticated=true
        tap(() => this.authenticationService.authSuccessful()),
        tap(() => this.navigateToWhereUserLeftOff()),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  private navigateToWhereUserLeftOff(): void {
    const referralUrl = sessionStorage.getItem(CookieConstants.REFERRAL_URL);
    sessionStorage.removeItem(CookieConstants.REFERRAL_URL);

    // referralUrl is only set, if the user left the application
    // due to a login
    if (!referralUrl) return;

    if (
      referralUrl.length > 1 &&
      !referralUrl.toLowerCase().includes('oidc-callback') &&
      !referralUrl.toLowerCase().includes('unauthorized')
    ) {
      // Ignore case when url is only /
      this.router.navigateByUrl(referralUrl);
    } else {
      this.router.navigate([DIM_DEFAULT_LANDING_PAGE]);
    }
  }

  title = 'msa-portal-ui';
  environment = environment;

  showRichContent$ = this.router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map(e => {
      return (<NavigationEnd>e).url === LANDING_PAGE_PATH;
    })
  );
}
