import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class MissingAuthorizationInterceptor implements HttpInterceptor {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return next.handle(request).pipe(
      catchError((error: unknown) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Handle 401 Unauthorized error
          return this.handle401Error(request, next);
        }
        // If it's not a 401 error, just pass it through
        return throwError(() => error);
      })
    );
  }

  private handle401Error<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    this.oidcSecurityService.authorize();
    return next.handle(request);
  }
}
