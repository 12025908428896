import {Action, State, StateContext} from '@ngxs/store';
import {ClearContactForm, UpdateContactFormGroup} from '@shared/shared-module/stores/actions/contact-form.actions';
import {ContactForm} from '@shared/shared-module/models/contact-form-data.model';
import {Injectable} from '@angular/core';

export interface FormGroupStateModel {
  contactFormGroup: ContactForm; // Use appropriate type here, or FormGroup
}

@State<ContactForm>({
  name: 'contactForm',
  defaults: {
    surname: null, //honeypot field
    concern: '',
    message: '',
    occurredAt: null,
    title: null,
    firstName: '',
    lastName: '',
    ahvNumber: '',
    email: '',
    phone: ''
  }
})
@Injectable()
export class ContactFormGroupState {
  @Action(UpdateContactFormGroup)
  updateFormGroup(ctx: StateContext<FormGroupStateModel>, action: UpdateContactFormGroup) {
    ctx.setState({
      contactFormGroup: action.payload
    });
  }

  @Action(ClearContactForm)
  clearContactForm(ctx: StateContext<FormGroupStateModel>) {
    ctx.patchState({
      contactFormGroup: undefined
    });
  }
}
